import React from "react";
import propertiesIcon from "../../assets/images/properties-icon.svg";
import "./nftProperties.scss";

const NFTProperties = ({ metaDataValue }) => {
  const metaData = JSON.parse(metaDataValue);

  return (
    <div className="user-nft-explorer-properties-wrapper">
      <div className="user-nft-explorer-properties-nft-details">
        <p className="user-nft-explorer-properties-nft-details-header-text">
          <img
            src={propertiesIcon}
            alt="nft"
            className="user-nft-explorer-properties-nft-details-header-icon"
          />
          Properties
        </p>

        {Array.isArray(metaData) && metaData?.length > 0 ? (
          metaData?.map((nft, index) => (
            <div
              className="user-nft-explorer-properties-nft-details-connections"
              key={index}
            >
              <span>{Object.keys(nft)[0] || "-"}</span>
              {[undefined, null].includes(nft["displayType"]) ? (
                <span style={{ float: "right" }}>{Object.values(nft)[0] || '-'}</span>
              ) : (
                <div style={{ width: "50%" }}>
                  {["string", "number"].includes(
                    nft["displayType"].toLowerCase()
                  ) && (
                    <span style={{ float: "right" }}>
                      {Object.values(nft)[0] || "-"}
                    </span>
                  )}
                  {nft["displayType"].toLowerCase() === "date" && (
                    <span style={{ float: "right" }}>
                      {Object.values(nft)[0] || "-"}
                    </span>
                  )}
                  {nft["displayType"].toLowerCase() === "boost_number" && (
                    <span style={{ float: "right" }}>
                      {Object.values(nft)[0] || "-"}
                    </span>
                  )}
                  {nft["displayType"].toLowerCase() === "boost_percentage" && (
                    <div>
                      <div
                        style={{ marginLeft: "-40px" }}
                        className="user-nft-explorer-properties-nft-details-connections-health-bar"
                      >
                        <span
                          style={{ width: `${Object.values(nft)[0]}%` }}
                        ></span>
                      </div>
                      <p style={{ float: "right", marginTop: "-15px" }}>
                        {Object.values(nft)[0]
                          ? `${Object.values(nft)[0]}%`
                          : "-"}
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <div className="user-nft-explorer-properties-nft-details-connections">
            No Meta data
          </div>
        )}
      </div>
    </div>
  );
};

export default NFTProperties;
