import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../components/nft-explorer-footer/Footer";
import Header from "../../components/nft-explorer-header/Header";
import NFTImage from "../nft-explorer-image/NFTImage";
import NFTOverview from "../nft-explorer-overview/NFTOverview";
import NftRevisions from "../nft-explorer-revisions/Revisions";
// import Statistics from "../nft-explorer-statistics/Statistics";
import { getRevisions, fetchNFTandCollections } from "../../service/api";
import Loader from "../../components/nft-explorer-loader/Loader";
import "../../styles/common.scss";
import "./home.scss";

const Home = () => {
  const [nft, setNft] = useState(null);
  const [revision, setRevisions] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { id } = useParams();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getRevisions({
        id,
      });
      const reverseRevision = result?.reverse()
      setRevisions(reverseRevision);
      const nftResult = await fetchNFTandCollections({
        id: id,
      });
      setNft(nftResult);
      setLoading(false);
    } catch (error) {
      setError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <div>
      <div className="home-wrapper">
        <Header />
        {/* <Statistics /> */}
        {error ? (
          <div style={{ display: "flex" }}>
            <span className="error">Something Went Wrong</span>
          </div>
        ) : (
          <div>
            {loading ? (
                <div className="explorer-home-loader">
                <Loader />
              </div>
            ) : (
              <>
                <div className="home-container">
                  <div className="nft-image">
                    <NFTImage nftData={nft} />
                  </div>
                  <div className="nft-overview">
                        <NFTOverview nftData={nft} revisionData={revision} />
                  </div>
                </div>
                <NftRevisions revisionData={revision} />
              </>
            )}
          </div>
        )}
        <div id="footer">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
