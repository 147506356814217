import React, { useState } from "react";
import { useParams } from "react-router-dom";
import informationIcon from "../../assets/images/information-icon.svg";
import successIcon from "../../assets/images/success-icon.svg";
import downloadProofIcon from "../../assets/images/download-proof-icon.svg";
import verifyOnEtherscanIcon from "../../assets/images/verify-on-etherscan-icon.svg";
import externalIcon from "../../assets/images/external-site-icon.svg";
import { downloadProof } from "../../service/api";
import { CircularProgress } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import "./nftTransactionProof.scss";

const NFTTransactionProof = ({ data, loader, time }) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const downloadProofClick = async () => {
    setLoading(true)
    const result = await downloadProof({ id });
    if (result.status === 200) {
      setLoading(false);
    } else {
      setLoading(true);
    }
    let proofFormat = result.data.proof.map((val) => `"${val}"`);
    if (result.data) {
      //Don't delete the gaps in userProof its for formatting
      delete data["deletedAt"];
      delete data["updatedAt"];
      delete data["createdAt"];
      delete data["id"];
      const userProof = `
    Revision-Data: ${JSON.stringify(data)}

    NFT ID: ${data.nftId}

    leaf: ${result.data.hex}

    proof: ${proofFormat}
  `;
      const element = document.createElement("a");
      const file = new Blob([userProof], {
        type: "text/plain",
      });
      element.href = URL.createObjectURL(file);
      element.download = `revision_${id}_proof_revise.txt`;
      document.body.appendChild(element);
      element.click();
    }
  };
  return (
    <div className="nft-transaction-proof-wrapper">
      <div className="nft-transaction-proof-details">
        <p className="nft-transaction-proof-details-title">
          Status{" "}
          <Tooltip title={<h2>Shows the status of Verification done at Revise’s end</h2>} arrow >
          <img
            src={informationIcon}
            alt="info"
              className="nft-transaction-proof-information-icon "

            />
          </Tooltip>
        </p>
        {loader ? (
          <p className="loader-dots">Verifying</p>
        ) : (
          <>
            <p className="nft-transaction-overview-details-success">
              <img src={successIcon} alt="icon" height={9} /> Success
            </p>
            <p className="nft-transaction-proof-details-success-time">
              Confirmed in under {time} seconds
            </p>
          </>
        )}
      </div>

      {/* <div className="nft-transaction-proof-details">
        <p className="nft-transaction-proof-details-title">
          Proof Generated{" "}
          <img
            src={informationIcon}
            alt="info"
            className="nft-transaction-proof-information-icon"
          />
        </p>
        <p className="nft-transaction-proof-details-proof-generated-time">
          45 Minutes ago
        </p>
      </div> */}

      <div
        className="nft-transaction-proof-details"
      >
        <p className="nft-transaction-proof-details-title">
          Download Proof
          <Tooltip title={<h2>Allows to download the proof</h2>} arrow>

          <img
            src={informationIcon}
            alt="info"
            className="nft-transaction-proof-information-icon"

            />
          </Tooltip>
        </p>
        <p className="nft-transaction-proof-details-download-proof-document">
        {loading ? (
            <CircularProgress color="inherit" size="17px" />
        ) : (
              <p onClick={() => downloadProofClick()}>
              <img
                src={downloadProofIcon}
                alt="icon"
                style={{ marginRight: "2px" }}
              />{" "}
              Download Proof
              </p>
        )}
        </p>
      </div>

      <div className="nft-transaction-proof-details">
        <p className="nft-transaction-proof-details-title">
          Verify Externally
          <Tooltip title={<h2>Allows you to independently verify the proof on third party explorers</h2>} arrow>

          <img
            src={informationIcon}
            alt="info"
              className="nft-transaction-proof-information-icon" />
          </Tooltip>
        </p>
        <a
          href="https://goerli.etherscan.io/address/0x6C07930B3Bc03E4eBe71DB2701A6cE9B37F90975#readContract"
          target="_blank"
          className="nft-explorer-verify-on-etherscan"
          rel="noreferrer">
          <p className="nft-transaction-proof-details-verification-on-etherscan">
            <img
              src={verifyOnEtherscanIcon}
              alt="etherscan"
              style={{ marginRight: "3px" }}
            />{" "}
            Verify manually on Etherscan{" "}
            <img
              src={externalIcon}
              alt="external-site"
              style={{ marginLeft: "3px" }}
            />{" "}
          </p>
        </a>
      </div>
    </div>
  );
};

export default NFTTransactionProof;
