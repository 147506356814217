import React, { useEffect, useState } from "react";
import informationIcon from "../../assets/images/information-icon.svg";
import successIcon from "../../assets/images/success-icon.svg";
import Loader from "../../components/nft-explorer-loader/Loader";
// import timestampIcon from "../../assets/images/timestamp-icon.svg";
// import blockIcon from "../../assets/images/block-icon.svg";
import Tooltip from '@mui/material/Tooltip';
import "./nftTransactionOverview.scss";
const openBrackets = "{";
const closeBrackets = "}";
const NFTTransactionOverview = ({ nftData, loader, time }) => {
  const [displayNftData, setDisplayNftData] = useState([]);

  const displayData = async () => {
    let dataToDisplay = [];
    if (nftData) {
      const {
        id,
        nftId,
        createdAt,
        image,
        description,
        mediaType,
        name,
        tokenId,
        animationUrl,
        metaData,
      } = nftData;
      dataToDisplay = [
        { name },
        { description },
        { image },
        { mediaType },
        { tokenId },
      ];
      if (metaData) dataToDisplay.push({ metaData: JSON.parse(metaData) });
      if (animationUrl) dataToDisplay.push(animationUrl);
      setDisplayNftData(dataToDisplay);
    }
  };

  useEffect(() => {
    displayData();
  }, [nftData]);

  return (
    <div className="nft-transaction-overview-wrapper">
      <div className="nft-transaction-overview-details">
        <p className="nft-transaction-overview-details-title" >
          Status
          <Tooltip title={<h2>Shows the status of Verification done at Revise’s end</h2>} arrow>
          <img
            src={informationIcon}
            alt="info"
              className="nft-transaction-overview-information-icon"
            />
          </Tooltip>
        </p>
        {
          loader ?
          <p className="loader-dots">
            Verifying
          </p>
          :
         <>
           <p className="nft-transaction-overview-details-success">
            <img src={successIcon} alt="icon" height={9} /> Success
          </p>
           <p className="nft-transaction-overview-details-success-time">
           Confirmed in under {time} seconds
         </p>
         </>
        }
       
      </div>

      <div className="nft-transaction-overview-details">
        <span className="nft-transaction-overview-details-title">
          Initiated by
          <Tooltip title={<h2>Shows the address that initiated this revision</h2>} arrow>

          <img
            src={informationIcon}
            alt="info"
              className="nft-transaction-overview-information-icon"
            />
          </Tooltip>
        </span>
        <span className="nft-transaction-overview-details-initiated-contract">
          0x6C07930B3Bc03E4eBe71DB2701A6cE9B37F90975
        </span>
      </div>

      {/* <div className="nft-transaction-overview-details">
        <span className="nft-transaction-overview-details-title">
          Timestamp{" "}
          <img
            src={informationIcon}
            alt="info"
            className="nft-transaction-overview-information-icon"
          />
        </span>
        <span className="nft-transaction-overview-details-timestamp-timing">
          <img src={timestampIcon} alt="time" height={9} /> 2 hrs 55 mins ago
          (Jan-09-2023 02:29:35 PM +UTC)
        </span>
      </div> */}

      {/* <div className="nft-transaction-overview-details">
        <span className="nft-transaction-overview-details-title">
          Block{" "}
          <img
            src={informationIcon}
            alt="info"
            className="nft-transaction-overview-information-icon"
          />
        </span> */}
      {/* <span className="nft-transaction-overview-details-block-count">
          <img
            src={blockIcon}
            alt="icon"
            height={12}
            style={{ marginRight: "3px" }}
          />{" "}
          13715350
        </span>
        <span
          className="nft-transaction-overview-details-confirmed-blocks"
          style={{ marginLeft: "3px" }}
        >
          9233 Block Confirmations
        </span> */}
      {/* </div> */}
      <div className="nft-transaction-overview-details">
        <div
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
        >
          <p className="nft-transaction-overview-details-title">
            NFT Data
            <Tooltip title={<h2>Shows the data of the NFT that user is trying to verify</h2>} arrow>

            <img
              src={informationIcon}
              alt="info"
                className="nft-transaction-overview-information-icon"
              />
            </Tooltip>
          </p>
        </div>
        <span className="nft-transaction-overview-details-nft-data">
          {
            loader ?
            <Loader small="small"/> 
            :
            <>
              {openBrackets}
              {Array.isArray(displayNftData) &&
                displayNftData?.map((nft, index) => {
                  return (
                    <div key={index}>
                      <span>{JSON.stringify(Object.keys(nft)[0]) || "-"}</span>:
                      <span>{JSON.stringify(Object.values(nft)[0]) || "-"},</span>
                    </div>
                  );
                })}
              {closeBrackets}
            </>
          }
        </span>
      </div>
    </div>
  );
};
export default NFTTransactionOverview;
