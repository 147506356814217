import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../../components/nft-explorer-footer/Footer";
import Header from "../../components/nft-explorer-header/Header";
import { getRevision } from "../../service/api";
// import Statistics from "../nft-explorer-statistics/Statistics";
import NFTTransactionOverview from "../nft-explorer-transaction-overview/NFTTransactionOverview";
import NFTTransactionProof from "../nft-explorer-transaction-proof/NFTTransactionProof";
import "./nftTransaction.scss";

const NFTTransaction = () => {
  const [toggleState, setToggleState] = useState(1);
  const [revision, setRevision] = useState(1);
  const { id } = useParams();
  const [loading, setLoading] = useState(true)
  const [timeTaken, setTimeTaken] = useState("")

  useEffect(() => {
    fetchRevision();
  }, [id]);

  const fetchRevision = async () => {
    const startTime = new Date()
    try {
      const revision = await getRevision({
        id,
      });
      const timeTaken = (new Date() - startTime);
      setTimeTaken(Math.ceil(timeTaken/1000))
      
      if(revision.status === 200){
        setLoading(false);
      }else{
        setLoading(true);
      }
      setRevision(revision.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <div className="nft-transaction-wrapper">
      <Header />
      {/* <Statistics /> */}
      <div className="nft-transaction-container">
        <div className="nft-transaction-details">
          <div
            className={
              toggleState === 1
                ? "nft-transaction-overview  active-nft-transaction-tab"
                : "nft-transaction-overview "
            }
            onClick={() => toggleTab(1)}
          >
            Overview
          </div>
          <div
            className={
              toggleState === 2
                ? "nft-transaction-proof  active-nft-transaction-tab"
                : "nft-transaction-proof "
            }
            onClick={() => toggleTab(2)}
          >
            Proof
          </div>
          {/* <div className="nft-transaction-state">State</div>
          <div className="nft-transaction-comments">Comments</div> */}
        </div>
        <div
          className={
            toggleState === 1
              ? "active-nft-transaction-content "
              : "nft-transaction-content"
          }
        >
          <NFTTransactionOverview nftData={revision} loader={loading} time={timeTaken}/>
        </div>
        <div
          className={
            toggleState === 2
              ? "active-nft-transaction-content"
              : "nft-transaction-content"
          }
        >
          <NFTTransactionProof data={revision} loader={loading} time={timeTaken}/>
        </div>
        {/* <div className={toggleState === 3 ? 'active-nft-transaction-content' : 'nft-transaction-content'}>
                    <NFTTransactionState />
                </div>
                <div className={toggleState === 4 ? 'active-nft-transaction-content' : 'nft-transaction-content'}>
                    <NFTTransactionComments />
                </div> */}
      </div>
      <div id="footer">
        <Footer />
      </div>
    </div>
  );
};

export default NFTTransaction;
