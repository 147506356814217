import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import ShowMoreText from "react-show-more-text";
import RevisionIcon from "../../assets/images/home/revisions.svg";
import CircleArrow from "../../assets/images/home/circledArrow.svg";
import Qtn from "../../assets/images/success-icon.svg";
import Logo from "../../assets/images/home/Logo.svg";
import BoxArrow from "../../assets/images/home/boxArrow.svg";

import "./revisions.scss";

const NftRevisions = ({ revisionData }) => {
  const [revisions, setRevisions] = useState([]);
  const [revisionsId, setRevisionsId] = useState([]);
  const [metaData, setMetaData] = useState([]);

  useEffect(() => {
    if (revisionData) {
      setRevisions(revisionData);
      const result = revisionData?.map((item) => JSON.parse(item.metaData));
      setMetaData(result);
    }
  }, [revisionData]);


  const handleDropDown = (revision) => {
    if (revisionsId.includes(revision)) {
      const result = revisionsId.filter((item) => item !== revision);
      setRevisionsId(result);
    } else {
      setRevisionsId([...revisionsId, revision]);
    }
  };

  return (
    <section className="revisions">
      <div className="container">
        <div className="revisionsContainer">
          <div className="revisionsHeading">
            <h2>
              <img src={RevisionIcon} alt="revisions" />
              Revisions
            </h2>
          </div>
          <div className="revisionsContent">
            <div className="revisionsTitle">
              <h3 className="w-6">Version</h3>
              <h3 className="w-94">Activity</h3>
            </div>
            {revisions.map((revision, i) => {
              return (
                <div className="accordion" key={i}>
                  <div
                    className={`revisionsRow ${
                      revisionsId.includes(revision.id) && "active"
                    }`}
                    onClick={() => handleDropDown(revision.id)}
                  >
                    <p className="w-6">{revisions.length - i - 1}</p>
                    <div className="revisionsRowData w-94">
                      <p>
                        <img src={CircleArrow} alt="id" />
                        <label>
                          {moment(revision.updatedAt).format(
                            "MMM Do, YYYY - h:mm a"
                          )}
                        </label>
                        {revision.attributesChanged}{" "}
                        {revision.attributesChanged === 1 ||
                        revision.attributesChanged === 0
                          ? "Attribute changed"
                          : "Attributes changed"}
                      </p>
                    </div>
                  </div>
                  {revisionsId.includes(revision.id) ? (
                    <div className="accordionAnswer">
                      <div className="revisionsStatus">
                        <div className="revisionsStatusRow">
                          <div>
                            <label>Status:</label>
                            <h3>
                              <img src={Qtn} alt="Pending" /> Confirmed
                            </h3>
                          </div>
                          <Link className="btnBlue" to={`/nft-revision/${revision.id}`}>
                            <img src={Logo} alt="revise" /> VERIFY
                          </Link>
                        </div>
                        <div className="revisionsContentRow">
                          <p>Name</p>
                          <p>:</p>
                          <p>{revision.name}</p>
                        </div>
                        <div className="revisionsContentRow">
                          <p>Image</p>
                          <p>:</p>
                          <div className="revisionsContentImg">
                            <div>
                              <label>{revision.image}</label>
                              <a
                                href={revision.image}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Open image <img src={BoxArrow} alt="arrow" />
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="revisionsContentRow">
                          <p>Description</p>
                          <p>:</p>
                          <p>
                            <ShowMoreText
                              lines={1}
                              more="See more"
                              less="See less"
                              anchorClass="show-more"
                              truncatedEndingComponent={"... "}
                            >
                              {revision.description}
                            </ShowMoreText>
                          </p>
                        </div>
                        {Object.entries(metaData[i]).map(([key, value], i) => {
                          return (
                            <div key={i}>
                              {Object.entries(value).map(([key, value,], i) => {
                                return key === "displayType" ? (
                                  ""
                                ) : (
                                  <div key={i} className="revisionsContentRow">
                                    <p>{key}</p>
                                    <p>:</p>
                                    <p>
                                      {Array.isArray(value)
                                        ? value.join(",")
                                        : value}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NftRevisions;
