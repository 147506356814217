import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { ColladaLoader } from "three/examples/jsm/loaders/ColladaLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { Html, useProgress } from "@react-three/drei";

function Loader() {
  const { progress } = useProgress();
  return (
    <Html
      center
      style={{
        backgroundColor: "black",
        borderRadius: "50%",
        color: "white",
        fontWeight: "bold",
        width: "50px",
        height: "50px",
        padding: "10px",
      }}
    >
      {Math.round(progress)} % loaded
    </Html>
  );
}

const GLTFModel = ({ url }) => {
  const gltf = useLoader(GLTFLoader, url);
  return (
    <>
      <primitive object={gltf.scene} />
    </>
  );
};

const FBXModel = ({ url }) => {
  const fbx = useLoader(FBXLoader, url);
  return (
    <>
      <primitive object={fbx} dispose={null} />
    </>
  );
};

const STLModel = ({ url }) => {
  const stl = useLoader(STLLoader, url);
  return (
    <>
      <mesh geometry={stl}>
        <meshPhongMaterial color="black" />
      </mesh>
    </>
  );
};

const OBJModel = ({ url }) => {
  const obj = useLoader(OBJLoader, url);
  return <primitive object={obj} />;
};

const DAEModel = ({ url }) => {
  const dae = useLoader(ColladaLoader, url);
  return <primitive object={dae.scene} dispose={null} />;
};

export { Loader, GLTFModel, FBXModel, STLModel, OBJModel, DAEModel };
