import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const fetchNFTandCollections = async ({ id }) => {
  const data = await axios.get(`${BASE_URL}/explorer/nft/${id}`);
  return data.data;
};

const getRevisions = async ({ id }) => {
  const rev = await axios.get(`${BASE_URL}/explorer/nft/revisions/${id}`);
  return rev.data;
};

const getRevision = async ({ id }) => {
  const rev = await axios.get(`${BASE_URL}/explorer/nft/revision/${id}`);
  return rev;
};

const downloadProof = async ({ id }) => {
  const rev = await axios.get(`${BASE_URL}/explorer/nft/createProof/${id}`);
  return rev;
};

export { fetchNFTandCollections, getRevisions, getRevision, downloadProof };
