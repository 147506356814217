import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/Logo.svg";
import rightArrow from "../../assets/images/Right-arrow.svg";
import "./header.scss";

const Header = () => {
  return (
    <div className="header-wrapper">
      <div className="image-wrapper">
        <Link to="/" className="logo-home-navigator">
          <img src={Logo} alt="Revise" />
        </Link>
      </div>
      <div className="search-bar-wrapper">
        <input
          type="search"
          className="input-search-bar"
          placeholder="Search by Address / ID / Hash / Block"
        />
        <img src={rightArrow} alt="search" className="arrow-right-search" />
      </div>
    </div>
  );
};

export default Header;
