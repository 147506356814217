/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import verifyButton from "../../assets/images/verify-button.svg";
import verifyIcon from "../../assets/images/verify-icon.svg";
import informationIcon from "../../assets/images/information-icon.svg";
import propertiesButtonIcon from "../../assets/images/properties-button-icon.svg";
import etherscanButtonIcon from "../../assets/images/etherscan-button-icon.svg";
import copyIcon from "../../assets/images/copy-icon.svg";
import closeIcon from "../../assets/images/close-icon.svg";
import { Link } from "react-router-dom";
import NFTProperties from "../nft-explorer-properties/NFTProperties";
import AboutNFT from "../about-nft-explorer/AboutNFT";
import noProfile from "../../assets/images/LogoMark.svg";
import "./NFTOverview.scss";

const NFTOverview = ({ nftData, revisionData }) => {
  const [properties, setProperties] = useState(false);
  const [about, setAbout] = useState(false);
  const [NFTOverviewContractAddress, setNFTOverviewContractAddress] =
    useState(null);
  const [
    maskedNFTOverviewContractAddress,
    setMaskedNFTOverviewContractAddress,
  ] = useState(null);
  const [userNFT, setUserNFT] = useState(null);
  const [userNFTRevisionId, setUserNFTRevisionId] = useState(null);
  const copyTextContent = useRef("");
  const copiedSuccess = useRef("");
  const nftOverviewPopUpModal = useRef("");

  useEffect(() => {
    if (nftData) {
      setUserNFT(nftData);
    }
    if (revisionData) {
      setUserNFTRevisionId(revisionData[revisionData?.length - 1]?.id);
    }
  }, [nftData]);

  useEffect(() => {
    fetchMaskedNFTOverviewContractAddress();
  }, [userNFT, NFTOverviewContractAddress]);

  const fetchMaskedNFTOverviewContractAddress = () => {
    if (userNFT?.collection?.info?.contractAddress) {
      let NFTOverviewContractAddress =
        userNFT?.collection?.info?.contractAddress;
      setNFTOverviewContractAddress(NFTOverviewContractAddress);
      if (
        NFTOverviewContractAddress !== null ||
        NFTOverviewContractAddress !== undefined
      ) {
        let NFTOverviewContractAddressFirstHalf =
          NFTOverviewContractAddress.substring(0, 4);
        let NFTOverviewContractAddressSecondHalf =
          NFTOverviewContractAddress.substring(
            4,
            NFTOverviewContractAddress.length - 5
          )
            .substring(1, 4)
            .replace(/./g, ".");
        let NFTOverviewContractAddressLastHalf =
          NFTOverviewContractAddress.substring(
            NFTOverviewContractAddress.length - 5
          );
        const maskedNFTOverviewContractAddress =
          NFTOverviewContractAddressFirstHalf +
          NFTOverviewContractAddressSecondHalf +
          NFTOverviewContractAddressLastHalf;

        setMaskedNFTOverviewContractAddress(maskedNFTOverviewContractAddress);
      }
    }
  };

  let etherscanURL = `https://etherscan.io/address/${NFTOverviewContractAddress}`;

  const modal = nftOverviewPopUpModal.current;

  async function popupProperties() {
    setProperties(true);
    setAbout(false);
    modal.style.display = "block";
  }

  async function popupAbout() {
    setAbout(true);
    setProperties(false);
    modal.style.display = "block";
  }

  function close() {
    modal.style.display = "none";
    setProperties(false);
    setAbout(false);
  }

  window.onclick = function (event) {
    if (event.target === modal) {
      modal.style.display = "none";
      setProperties(false);
      setAbout(false);
    }
  };
  return (
    <div className="user-nft-explorer-overview-wrapper">
      <div className="user-nft-explorer-overview-nft-club-details">
        <div className="user-nft-explorer-overview-nft">
          <p className="user-nft-explorer-overview-nft-header-name">
            {userNFT?.name ? userNFT?.name : "NULL"}
          </p>

          <div className="user-nft-explorer-overview-nft-club-wrapper">
            <img
              src={noProfile}
              alt="nft"
              className="user-nft-explorer-overview-nft-club-image"
            />
            <p className="user-nft-explorer-overview-nft-name">
              {userNFT?.collection?.collectionName
                ? userNFT?.collection?.collectionName
                : "No Collection added"}
            </p>
            {userNFT?.collection?.collectionName && (
              <img src={verifyIcon} alt="verified" />
            )}
          </div>
        </div>
        <Link
          to={`/nft-revision/${userNFTRevisionId}`}
          className="button-nft-transaction-navigator"
        >
          <button className="user-nft-explorer-overview-nft-verify-button">
            <img
              src={verifyButton}
              alt="revise"
              className="user-nft-explorer-overview-nft-verify-button-image"
            />
            Verify
          </button>
        </Link>
      </div>

      <div className="user-nft-explorer-overview-nft-details">
        <div style={{ display: "flex", margin: "35px 0px" }}>
          <div className="user-nft-explorer-overview-nft-details-tokenId">
            <span className="user-nft-explorer-overview-nft-details-tokenId-title">
              Token ID
            </span>
            <span
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                id="user-nft-explorer-overview-nft-details-tokenId-id"
                ref={copyTextContent}
              >
                {userNFT?.tokenId ? userNFT?.tokenId : "NULL"}
              </span>
            </span>
          </div>

          <div className="user-nft-explorer-overview-nft-details-owner">
            <span className="user-nft-explorer-overview-nft-details-owner-title">
              Owner
            </span>
            <span>
              <span className="user-nft-explorer-overview-nft-details-owner-id">
                {maskedNFTOverviewContractAddress
                  ? maskedNFTOverviewContractAddress
                  : "NULL"}
              </span>
            </span>
          </div>

          <div
            className="copied-success"
            style={{ display: userNFT?.tokenId ? "flex" : "none" }}
          ></div>
        </div>

        <div className="user-nft-explorer-overview-nft-details-Row">
          <p>Classification</p>
          <p>:</p>
          <p> Off-Chain (IPFS)</p>
        </div>

        <div className="user-nft-explorer-overview-nft-details-Row">
          <p>Token Standard</p>
          <p>:</p>
          <p> {userNFT?.format ? userNFT?.format : "NULL"}</p>
        </div>

        <div style={{ display: "flex", marginTop: "30px" }}>
          <div
            className="user-nft-explorer-overview-nft-details-properties"
            onClick={popupProperties}
          >
            <img
              src={propertiesButtonIcon}
              alt="icon"
              className="user-nft-explorer-overview-nft-details-button-icons"
            />
            <p className="text-content">Properties</p>
          </div>

          <div
            className="user-nft-explorer-overview-nft-details-about"
            onClick={popupAbout}
          >
            <img
              src={informationIcon}
              alt="info"
              className="user-nft-explorer-overview-nft-details-information-icon user-nft-explorer-overview-nft-details-button-icons"
            />
            <p className="text-content">About</p>
          </div>

          <a
            href={etherscanURL}
            target="_blank"
            rel="noreferrer"
            className="user-nft-explorer-overview-nft-details-etherscan-link"
          >
            <div
              className="user-nft-explorer-overview-nft-details-etherscan"
              style={{
                display: NFTOverviewContractAddress ? "flex" : "none",
              }}
            >
              <img
                src={etherscanButtonIcon}
                alt="icon"
                className="user-nft-explorer-overview-nft-details-button-icons"
                style={{ height: "22px", width: "22px" }}
              />
              Etherscan
            </div>
          </a>
        </div>
      </div>

      <div
        id="nft-overview-popup-modal"
        ref={nftOverviewPopUpModal}
        className="nft-overview-popup-modal"
      >
        <div className="nft-overview-popup-modal-content">
          <span className="close-popup" onClick={close}>
            <img src={closeIcon} alt="" className="close-icon" />
            Close
          </span>
          {properties ? (
            <NFTProperties metaDataValue={userNFT?.metaData} />
          ) : (
            ""
          )}
          {about ? <AboutNFT userNFT={userNFT} /> : ""}
        </div>
      </div>
    </div>
  );
};

export default NFTOverview;
