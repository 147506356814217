import React, { useEffect, useState } from "react";
import aboutFacebook from "../../assets/images/about-facebook.svg";
import aboutInstagram from "../../assets/images/about-instagram.svg";
import aboutTwitter from "../../assets/images/about-twitter.svg";
import aboutDiscord from "../../assets/images/about-discord.svg";
import verifyIcon from "../../assets/images/verify-icon.svg";
import aboutIcon from "../../assets/images/about-icon.svg";
import noProfile from "../../assets/images/LogoMark.svg";
import "./aboutNFT.scss";

const AboutNFT = ({ userNFT }) => {
  const [maskedNFTAboutContractAddress, setMaskedNFTAboutContractAddress] =
    useState(null);
  const [Twitter, setTwitter] = useState("");
  const [Instagram, setInstagram] = useState("");
  const [Discord, setDiscord] = useState("");
  const [Facebook, setFacebook] = useState("");

  const contractAddress = userNFT?.collection?.info?.contractAddress
    ? userNFT?.collection?.info?.contractAddress
    : null;
  const socialMediaProfiles = userNFT?.collection?.info?.socialMedia
    ? userNFT?.collection?.info?.socialMedia
    : [];

  useEffect(() => {
    fetchMaskedNFTAboutContractAddress();
    fetchSocialMediaProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socialMediaProfiles, userNFT, contractAddress]);

  const fetchMaskedNFTAboutContractAddress = () => {
    if (contractAddress !== null) {
      const NFTAboutContractAddressFirstHalf = contractAddress.substring(0, 7);
      const NFTAboutContractAddressSecondHalf = contractAddress
        .substring(7, contractAddress.length - 5)
        .substring(1, 4)
        .replace(/./g, ".");
      const NFTAboutContractAddressLastHalf = contractAddress.substring(
        contractAddress.length - 5
      );
      const maskedNFTAboutContractAddress =
        NFTAboutContractAddressFirstHalf +
        NFTAboutContractAddressSecondHalf +
        NFTAboutContractAddressLastHalf;
      setMaskedNFTAboutContractAddress(maskedNFTAboutContractAddress);
    }
  };

  const fetchSocialMediaProfiles = () => {
    if (Array.isArray(socialMediaProfiles)) {
      socialMediaProfiles.map((socialMediaProfile) => {
        if (socialMediaProfile.name === "twitter") {
          setTwitter(socialMediaProfile.link);
        } else if (socialMediaProfile.name === "facebook") {
          setFacebook(socialMediaProfile.link);
        } else if (socialMediaProfile.name === "instagram") {
          setInstagram(socialMediaProfile.link);
        } else if (socialMediaProfile.name === "discord") {
          setDiscord(socialMediaProfile.link);
        }
        return socialMediaProfile;
      });
    }
  };

  return (
    <>
      {
        <div className="user-nft-explorer-about-wrapper">
          <div className="user-nft-explorer-about-nft-details">
            <p className="user-nft-explorer-about-nft-details-header-text">
              <img
                src={aboutIcon}
                alt="nft"
                className="user-nft-explorer-about-nft-details-header-icon"
              />{" "}
              About
            </p>
            <div className="user-nft-explorer-about-nft-club-details">
              <div className="user-nft-explorer-about-nft">
                <img
                  src={noProfile}
                  alt="nft"
                  className="user-nft-explorer-about-nft-club-image"
                />
              </div>
              {userNFT?.collection ? (
                <div className="user-nft-explorer-about-nft-club-wrapper">
                  <p className="user-nft-explorer-about-nft-name">
                    {userNFT.collection?.collectionName
                      ? userNFT.collection?.collectionName
                      : "NULL"}
                    {userNFT?.collection?.collectionName && (
                      <img
                        src={verifyIcon}
                        alt="verified"
                        className="user-nft-explorer-about-nft-verify-icon"
                      />
                    )}
                  </p>
                  <p className="user-nft-explorer-about-nft-token">
                    Token: {userNFT?.tokenId ? userNFT?.tokenId : "NULL"}
                  </p>
                  <p className="user-nft-explorer-about-nft-abbr">
                    Non Fungible Token (NFT)
                  </p>
                </div>
              ) : (
                <p className="user-nft-explorer-about-collection-not-added">
                  No Collection added
                </p>
              )}
            </div>
            {/* <div className='user-nft-explorer-about-nft-details-connections'>
                        <span>Total Supply </span>
                        <span>100,000</span>
                    </div> */}
            <div className="user-nft-explorer-about-nft-details-connections">
              <span>Contract </span>
              <span className="user-nft-explorer-about-nft-details-connections-contract">
                {maskedNFTAboutContractAddress
                  ? maskedNFTAboutContractAddress
                  : "NULL"}
              </span>
            </div>
            <div className="user-nft-explorer-about-nft-details-connections">
              <span>Official Website </span>
              <span className="user-nft-explorer-about-nft-details-connections-website">
                {userNFT?.collection?.info?.officialWebsite
                  ? userNFT?.collection?.info?.officialWebsite
                  : "no website added"}
              </span>
            </div>
            <div className="user-nft-explorer-about-nft-details-connections">
              <span>Social Profiles </span>
              {socialMediaProfiles.length !== 0 ? (
                <div className="user-nft-explorer-about-social-icons">
                  {Facebook ? (
                    <a href={Facebook} target="_blank" rel="noreferrer">
                      <img
                        src={aboutFacebook}
                        alt="revise"
                        className="user-nft-explorer-about-social-icons-widgets"
                      />
                    </a>
                  ) : (
                    ""
                  )}
                  {Instagram ? (
                    <a href={Instagram} target="_blank" rel="noreferrer">
                      <img
                        src={aboutInstagram}
                        alt="revise"
                        className="user-nft-explorer-about-social-icons-widgets"
                      />
                    </a>
                  ) : (
                    ""
                  )}
                  {Twitter ? (
                    <a href={Twitter} target="_blank" rel="noreferrer">
                      <img
                        src={aboutTwitter}
                        alt="revise"
                        className="user-nft-explorer-about-social-icons-widgets"
                      />
                    </a>
                  ) : (
                    ""
                  )}
                  {Discord ? (
                    <a href={Discord} target="_blank" rel="noreferrer">
                      <img src={aboutDiscord} alt="revise" />
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                "No Profiles added"
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default AboutNFT;
