/* eslint-disable jsx-a11y/img-redundant-alt */
import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  DAEModel,
  FBXModel,
  GLTFModel,
  Loader,
  OBJModel,
  STLModel,
} from "../nft-explorer-three-d-models/ThreeDModels";
import HomeLoader from "../../components/nft-explorer-loader/Loader";
import "./NFTImage.scss";

const NFTImage = ({ nftData }) => {
  const ref = useRef();
  const [userNFT, setUserNFT] = useState(null);
  const [threeD, setThreeD] = useState(false);
  const [nftImage, setNFTImage] = useState(null);
  const [nftAnimationUrl, setNFTAnimationUrl] = useState(null);
  const [nftMediaType, setNFTMediaType] = useState(null);
  const [gltfObject, setGLTFObject] = useState(false);
  const [fbxObject, setFBXObject] = useState(false);
  const [stlObject, setSTLObject] = useState(false);
  const [objObject, setOBJObject] = useState(false);
  const [daeObject, setDAEObject] = useState(false);

  useEffect(() => {
    if (nftData) {
      setUserNFT(nftData);
      createTag();
    }
  }, [nftData, threeD, nftImage, nftAnimationUrl]);

  async function createTag() {
    const imageWrapper = ref.current;
    setNFTImage(userNFT?.image);
    setNFTAnimationUrl(userNFT?.animationUrl);
    setNFTMediaType(userNFT?.mediaType);
    if (!imageWrapper) return;

    if ("video" === nftMediaType?.toLowerCase()) {
      imageWrapper.removeChild(imageWrapper.firstChild);

      const videoTag = document.createElement("video");
      videoTag.controls = true;
      videoTag.muted = true;
      videoTag.loop = true;
      videoTag.autoplay = true;
      videoTag.controlsList = "nodownload";
      videoTag.classList.add("w-100, user-nft-explorer-image");
      videoTag.style.height = "100%";
      videoTag.style.backgroundSize = "cover";
      const sourceTag = document.createElement("source");
      sourceTag.src = nftAnimationUrl;
      videoTag.appendChild(sourceTag);
      imageWrapper.appendChild(videoTag);

      setThreeD(false);
    } else if ("3dobj" === nftMediaType?.toLowerCase()) {
      setThreeD(true);

      const typeOfThreeDObject = nftAnimationUrl
        ?.split(".")
        [nftAnimationUrl?.split(".").length - 1]?.toLowerCase();
      if ("gltf" === typeOfThreeDObject) {
        setGLTFObject(true);
        setFBXObject(false);
        setSTLObject(false);
        setOBJObject(false);
        setDAEObject(false);
      } else if ("fbx" === typeOfThreeDObject) {
        setGLTFObject(false);
        setFBXObject(true);
        setSTLObject(false);
        setOBJObject(false);
        setDAEObject(false);
      } else if ("stl" === typeOfThreeDObject) {
        setGLTFObject(false);
        setFBXObject(false);
        setSTLObject(true);
        setOBJObject(false);
        setDAEObject(false);
      } else if ("obj" === typeOfThreeDObject) {
        setGLTFObject(false);
        setFBXObject(false);
        setSTLObject(false);
        setOBJObject(true);
        setDAEObject(false);
      } else if ("dae" === typeOfThreeDObject) {
        setGLTFObject(false);
        setFBXObject(false);
        setSTLObject(false);
        setOBJObject(false);
        setDAEObject(true);
      } else {
        setGLTFObject(false);
        setFBXObject(false);
        setSTLObject(false);
        setOBJObject(false);
        setDAEObject(false);
        imageWrapper.removeChild(imageWrapper.firstChild);
        const iframeTag = document.createElement("iframe");
        iframeTag.style.height = "100%";
        iframeTag.style.backgroundSize = "cover";
        iframeTag.classList.add("user-nft-explorer-image");
        iframeTag.setAttribute("src", nftAnimationUrl);
        imageWrapper.appendChild(iframeTag);
      }
    } else if ("iframe" === nftMediaType?.toLowerCase()) {
      imageWrapper.removeChild(imageWrapper.firstChild);

      const iframeTag = document.createElement("iframe");
      iframeTag.style.height = "100%";
      iframeTag.style.backgroundSize = "cover";
      iframeTag.classList.add("user-nft-explorer-image");
      iframeTag.setAttribute("src", nftAnimationUrl);
      imageWrapper.appendChild(iframeTag);
      setThreeD(false);
    } else {
      imageWrapper.removeChild(imageWrapper.firstChild);
      const imageTag = document.createElement("img");
      imageTag.style.height = "100%";
      imageTag.style.weight = "100%";
      imageTag.style.backgroundSize = "cover";
      imageTag.src = nftImage;
      imageTag.alt = "NFT";
      imageTag.classList.add("user-nft-explorer-image");
      imageWrapper.appendChild(imageTag);

      setThreeD(false);
    }
  }

  return (
    <div className="user-nft-explorer-image-wrapper">
      <div className="user-nft-explorer-image-block" ref={ref}>
        <HomeLoader />
        {threeD ? (
          <Canvas height="500px" className="user-nft-explorer-image">
            <Suspense fallback={<Loader />}>
              {gltfObject && <GLTFModel url={nftAnimationUrl} />}
              {fbxObject && <FBXModel url={nftAnimationUrl} />}
              {stlObject && <STLModel url={nftAnimationUrl} />}
              {objObject && <OBJModel url={nftAnimationUrl} />}
              {daeObject && <DAEModel url={nftAnimationUrl} />}
              <OrbitControls panSpeed={0.1} rotateSpeed={0.2} autoRotate />
              {/* <Environment preset="sunset" background /> */}
            </Suspense>
            <spotLight position={[10, 15, 10]} angle={0.9} />
          </Canvas>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default NFTImage;
